define("discourse/plugins/discourse-pdftron-search/discourse/initializers/Pill", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const colors = {
    web: 'rgb(246, 222, 59)',
    blue: '#00a5e4',
    xamarin: 'rgb(57, 148, 210)',
    android: 'rgb(160,191,67)',
    ios: 'rgb(156,84,184)',
    windows: 'rgb(129,186,80)',
    linux: 'rgb(238,182,46)',
    sample: 'red',
    api: 'rgb(235, 52, 149)'
  };
  var _default = _exports.default = {
    name: 'Pill',
    initialize() {},
    pill(_ref) {
      let {
        text,
        color
      } = _ref;
      if (!text) {
        return '';
      }
      return `
      <span class='pill' style='background-color: ${colors[color] || color}'>
        ${text}
      </span>
    `;
    }
  };
});