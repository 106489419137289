define("discourse/plugins/discourse-pdftron-search/discourse/initializers/platforms", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const WEB = 'web';
  const IOS = 'ios';
  const ANDROID = 'android';
  const XAMARIN = 'xamarin';
  const UWP = 'uwp';
  const LINUX = 'linux';
  const WINDOWS = 'windows';
  const MAC = 'mac';
  const CLI = 'cli';
  const CORE = 'core';
  const DOTNET = 'dotnet';
  const DOTNETCORE = 'dotnetcore';
  const NODEJS = 'nodejs';
  const JAVA = 'java';
  const CPP = 'cpp';
  const PHP = 'php';
  const PYTHON = 'python';
  const RUBY = 'ruby';
  var _default = _exports.default = {
    name: 'platforms',
    initialize() {},
    OFFICIAL_PLATFORM_MAP: {
      WEB,
      IOS,
      ANDROID,
      XAMARIN,
      UWP,
      LINUX,
      WINDOWS,
      MAC,
      CLI,
      CORE,
      DOTNET,
      DOTNETCORE,
      NODEJS,
      JAVA,
      CPP,
      PHP,
      PYTHON,
      RUBY
    }
  };
});