define("discourse/plugins/discourse-pdftron-search/discourse/initializers/languages", ["exports", "discourse/plugins/discourse-pdftron-search/discourse/initializers/platforms"], function (_exports, _platforms) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    IOS,
    UWP,
    MAC,
    DOTNETCORE,
    NODEJS,
    DOTNET,
    PHP,
    CPP,
    WEB,
    CORE
  } = _platforms.default.OFFICIAL_PLATFORM_MAP;
  const FORMATTED_LANGUAGES = {
    c: 'C',
    csharp: 'C#',
    cs: 'C#',
    cpp: 'C++',
    java: 'Java',
    objectivec: 'Obj-C',
    swift: 'Swift',
    markup: 'Markup',
    css: 'CSS',
    html: 'HTML',
    js: 'JavaScript',
    javascript: 'JavaScript',
    jsx: 'React JSX',
    json: 'JSON',
    windows: 'Windows',
    mac: 'Mac',
    linux: 'Linux',
    objc: 'Objective-C',
    xml: 'XML',
    xaml: 'XAML',
    python: 'Python',
    ruby: 'Ruby',
    php: 'PHP',
    kotlin: 'Kotlin',
    vb: 'VB',
    es5: 'ES5',
    'es6+': 'ES6+',
    '6.0js': 'v6.0+',
    '7.0js': 'v7.0+',
    '5.1html': 'v5.1+',
    'xamarin.cs': 'C# (Xamarin)',
    'dotnetcore.cs': 'C# (.NET Core)',
    'android.java': 'Java (Android)',
    rb: 'Ruby',
    py: 'Python',
    m: 'Obj-C',
    kt: 'Kotlin',
    'uwp.cs': 'C# (UWP)',
    'node.js': 'JS (Node.js)',
    scss: 'SCSS',
    apex: 'apex'
  };
  const replacementLabels = {
    [CORE]: 'Cross-Platform (Core)',
    [DOTNET]: 'C# .NET',
    [WEB]: "JavaScript"
  };
  var _default = _exports.default = {
    name: 'languages',
    initialize() {},
    getPlatformSpellingForTitle: (spelling, useReplacementTable) => {
      const lower = (spelling || '').toLowerCase();
      if (useReplacementTable && replacementLabels[lower]) {
        return replacementLabels[lower];
      }
      if (lower === IOS) {
        return 'iOS';
      }
      if (lower === UWP) {
        return 'UWP';
      }
      if (lower === MAC) {
        return 'macOS';
      }
      if (lower === DOTNETCORE) {
        return '.NET Core';
      }
      if (lower === NODEJS) {
        return 'Node.js';
      }
      if (lower === DOTNET) {
        return '.NET Framework';
      }
      if (lower === PHP) {
        return 'PHP';
      }
      if (lower === CPP) {
        return 'C++';
      }
      if (spelling && spelling[0] === spelling[0].toUpperCase()) {
        return spelling;
      }
      return lower.charAt(0).toUpperCase() + lower.substr(1);
    },
    getUserFacingLanguage: language => {
      return FORMATTED_LANGUAGES[language] || language;
    }
  };
});