define("discourse/plugins/discourse-pdftron-search/discourse/initializers/discourse-pdftron-search", ["exports", "@ember/render-modifiers/modifiers/did-insert", "discourse/lib/api", "discourse/lib/load-script", "discourse-common/config/environment", "I18n", "discourse/plugins/discourse-pdftron-search/discourse/initializers/util", "discourse/plugins/discourse-pdftron-search/discourse/initializers/Pill", "discourse/plugins/discourse-pdftron-search/discourse/initializers/languages", "discourse-common/helpers/d-icon", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _didInsert, _api, _loadScript, _environment, _I18n, _util, _Pill, _languages, _dIcon, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const toggleModal = () => {
    const search = document.querySelector(".pdftron-search-container");
    search.classList.toggle("closed");
    // auto focus search input
    if (!search.classList.contains("closed")) {
      const searchInput = document.querySelector(".ais-SearchBox-input");
      searchInput.focus();
    }
  };
  function initializeAutocomplete(options) {
    const {
      pill
    } = _Pill.default;
    const {
      getPlatformSpellingForTitle,
      getUserFacingLanguage
    } = _languages.default;
    const {
      getSlugFromHit
    } = _util.default;
    const searchClient = algoliasearch(options.algoliaApplicationId, options.algoliaSearchApiKey);
    const search = instantsearch({
      indexName: options.indexName,
      stalledSearchDelay: 500,
      searchClient
    });
    const transformRefinementListItem = items => {
      return items.map(item => ({
        ...item,
        highlighted: getPlatformSpellingForTitle(getUserFacingLanguage(item.highlighted))
      }));
    };
    // Create the render function for hits
    const renderHits = renderOptions => {
      const {
        hits,
        widgetParams
      } = renderOptions;
      widgetParams.container.innerHTML = `
          ${hits.map(hit => {
        const hitSlug = getSlugFromHit(hit);
        const hitLink = hitSlug.includes("http") ? hitSlug : `https://docs.apryse.com/${hitSlug}`;
        const h2Highlight = hit.h2 ? `
                <h4>
                  ${instantsearch.highlight({
          attribute: "h2",
          hit,
          highlightedTagName: "span"
        })}
                </h4>
                ` : "";
        const h3Highlight = hit.h3 ? `
                <h5>
                  ${instantsearch.highlight({
          attribute: "h3",
          hit,
          highlightedTagName: "span"
        })}
                </h5>
                ` : "";
        return `
                <a class='Link hit-link' href='${hitLink}'>
                  <div class='hit'>
                    <div class='hit-title'>
                      <h3>
                        ${instantsearch.highlight({
          attribute: "h1",
          hit,
          highlightedTagName: "span"
        })}
                      </h3>
                      ${pill({
          text: hit.platform,
          color: hit.platform
        })}
                      ${pill({
          text: hit.type,
          color: hit.type
        })}
                    </div>

                    ${h2Highlight}
                    ${h3Highlight}
                    <p>${instantsearch.snippet({
          attribute: "content",
          hit
        })}</p>
                  </div>
                </a>`;
      }).join("")}
      `;
      const links = widgetParams.container.querySelectorAll(".hit-link");
      links.forEach(link => link.addEventListener("click", () => toggleModal()));
    };
    // Create the custom hit widget
    const customHits = instantsearch.connectors.connectHits(renderHits);
    search.addWidgets([instantsearch.widgets.searchBox({
      container: "#algolia-search-box",
      placeholder: _I18n.default.t("discourse_pdftron_search.search_box_placeholder"),
      showLoadingIndicator: true
    }), instantsearch.widgets.stats({
      container: "#stats"
    }), instantsearch.widgets.menuSelect({
      container: "#refinement-platform",
      attribute: "platform",
      showMore: true,
      limit: 30,
      sortBy: ["count:desc"],
      // templates: {
      //   defaultOption: 'See all'
      // },
      transformItems(items) {
        return items.reduce((acc, item) => {
          if (item.label === "blog" || item.label === "guides") {
            return acc;
          }
          acc.push({
            ...item,
            label: getPlatformSpellingForTitle(getUserFacingLanguage(item.label))
          });
          return acc;
        }, []);
      }
    }), instantsearch.widgets.refinementList({
      container: "#refinement-type",
      attribute: "type",
      showMore: true,
      limit: 8,
      sortBy: ["count:desc", "name:asc"],
      transformItems(items) {
        return transformRefinementListItem(items);
      }
    }), instantsearch.widgets.refinementList({
      container: "#refinement-tags",
      attribute: "tags",
      showMore: true,
      limit: 8,
      sortBy: ["count:desc", "name:asc"],
      transformItems(items) {
        return transformRefinementListItem(items);
      }
    }), instantsearch.widgets.configure({
      attributesToSnippet: ["content"],
      disjunctiveFacetsRefinements: {
        type: ["forum", "guide", "api"]
      }
    }), customHits({
      container: document.querySelector(".hits")
    })]);
    search.start();
  }
  var _default = _exports.default = (0, _api.apiInitializer)("0.8", api => {
    const siteSettings = api.container.lookup("service:site-settings");
    const shouldDisplay = () => siteSettings.pdftron_search_enabled;
    let search;
    function renderAlgolia() {
      search?.destroy();
      document.body.classList.add("pdftron-search-enabled");
      Promise.all([(0, _loadScript.default)("/plugins/discourse-pdftron-search/javascripts/algoliasearch.js"), (0, _loadScript.default)("/plugins/discourse-pdftron-search/javascripts/instantsearch.production.min.js")]).then(() => {
        const closeElement = document.querySelector(".close");
        closeElement.onclick = () => toggleModal();
        const backdropElement = document.querySelector(".backdrop");
        backdropElement.onclick = () => toggleModal();
        const closeMobileElement = document.querySelector(".close-mobile");
        closeMobileElement.onclick = () => toggleModal();
        search = initializeAutocomplete({
          algoliaApplicationId: siteSettings.algolia_application_id,
          algoliaSearchApiKey: siteSettings.algolia_search_api_key,
          imageBaseURL: "",
          debug: (0, _environment.isDevelopment)(),
          indexName: siteSettings.algolia_index_name
        });
      });
    }
    api.headerIcons.add("pdftron-search-button", (0, _component.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        {{#if (shouldDisplay)}}
          <li {{didInsert renderAlgolia}}>
            <a class="btn no-text icon btn-flat" onclick={{toggleModal}}>
              {{icon "fa-magnifying-glass" label="pdftron-search-button"}}
            </a>
          </li>
        {{/if}}
      
    */
    {
      "id": "+5otNBPG",
      "block": "[[[1,\"\\n\"],[41,[28,[32,0],null,null],[[[1,\"      \"],[11,\"li\"],[4,[32,1],[[32,2]],null],[12],[1,\"\\n        \"],[10,3],[14,0,\"btn no-text icon btn-flat\"],[15,\"onclick\",[32,3]],[12],[1,\"\\n          \"],[1,[28,[32,4],[\"fa-magnifying-glass\"],[[\"label\"],[\"pdftron-search-button\"]]]],[1,\"\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]],null],[1,\"  \"]],[],false,[\"if\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-pdftron-search/discourse/initializers/discourse-pdftron-search.js",
      "scope": () => [shouldDisplay, _didInsert.default, renderAlgolia, toggleModal, _dIcon.default],
      "isStrictMode": true
    }), (0, _templateOnly.default)(undefined, undefined)));
  });
});