define("discourse/plugins/discourse-pdftron-search/discourse/initializers/util", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const formatStringForId = s => {
    s = s.replace(/\*/g, '');
    s = s.replace(/\([\s\S]*?(\)|$)/g, '');
    s = s.replace(/<(.*?)>/g, '');
    s = s.replace(/\[(.*?)\]/g, '');
    s = s.replace(/'|"|’/g, '');
    // eslint-disable-next-line
    s = s.replace(/\?|\!/g, '');
    s = s.replace(/-|\(|\)|\.|,/g, '');
    s = s.replace(/\//g, ' ');
    s = s.replace(/\s{2,}/g, ' ');
    s = s.trim().split(' ').join('-');
    return s.toLowerCase();
  };
  var _default = _exports.default = {
    name: 'util',
    initialize() {},
    getSlugFromHit: hit => {
      let base = hit.slug;
      if (hit.h3) {
        base += `#${formatStringForId(hit.h3)}`;
        return base;
      }
      if (hit.h2) {
        base += `#${formatStringForId(hit.h2)}`;
        return base;
      }
      return base;
    }
  };
});