define("discourse/plugins/discourse-pdftron-search/discourse/connectors/home-logo__after/pdftron-search", ["exports", "@glimmer/component", "@ember/service", "discourse-common/helpers/d-icon"], function (_exports, _component, _service, _dIcon) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // https://meta.discourse.org/t/using-plugin-outlet-connectors-from-a-theme-or-plugin/32727
  class PdftronSearch extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "siteSettings", [_service.inject]))();
    #siteSettings = (() => (dt7948.i(this, "siteSettings"), void 0))();
    get icon() {
      return _dIcon.default;
    }
    get shouldDisplay() {
      return this.siteSettings.pdftron_search_enabled;
    }
  }
  _exports.default = PdftronSearch;
});