define("discourse/plugins/discourse-pdftron-search/discourse/templates/connectors/home-logo__after/pdftron-search", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    
  
  {{#if this.shouldDisplay}}
    <div class="pdftron-search-container closed">
      <div class="search-modal">
        <div class="backdrop"></div>
        <div class="content">
          <div class="close">
            {{this.icon "fa-xmark" label="pdftron-search-close-button"}}
          </div>
          <div class="refinements">
            <h3>Platform</h3>
            <div id="refinement-platform"></div>
            <h3>Type</h3>
            <div id="refinement-type"></div>
            <h3>Tags</h3>
            <div id="refinement-tags"></div>
          </div>
          <div class="results">
            <div class="close-mobile">
              <span class="close-mobile-icon">
                {{this.icon "fa-xmark" label="pdftron-search-close-button"}}
              </span>
            </div>
            <div class="search-box">
              <div id="algolia-search-box"></div>
              <div id="stats"></div>
            </div>
            <div class="hits"></div>
          </div>
        </div>
      </div>
    </div>
  {{/if}}
  */
  {
    "id": "siZ+Jzrh",
    "block": "[[[1,\"\\n\\n\"],[41,[30,0,[\"shouldDisplay\"]],[[[1,\"  \"],[10,0],[14,0,\"pdftron-search-container closed\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"search-modal\"],[12],[1,\"\\n      \"],[10,0],[14,0,\"backdrop\"],[12],[13],[1,\"\\n      \"],[10,0],[14,0,\"content\"],[12],[1,\"\\n        \"],[10,0],[14,0,\"close\"],[12],[1,\"\\n          \"],[1,[28,[30,0,[\"icon\"]],[\"fa-xmark\"],[[\"label\"],[\"pdftron-search-close-button\"]]]],[1,\"\\n        \"],[13],[1,\"\\n        \"],[10,0],[14,0,\"refinements\"],[12],[1,\"\\n          \"],[10,\"h3\"],[12],[1,\"Platform\"],[13],[1,\"\\n          \"],[10,0],[14,1,\"refinement-platform\"],[12],[13],[1,\"\\n          \"],[10,\"h3\"],[12],[1,\"Type\"],[13],[1,\"\\n          \"],[10,0],[14,1,\"refinement-type\"],[12],[13],[1,\"\\n          \"],[10,\"h3\"],[12],[1,\"Tags\"],[13],[1,\"\\n          \"],[10,0],[14,1,\"refinement-tags\"],[12],[13],[1,\"\\n        \"],[13],[1,\"\\n        \"],[10,0],[14,0,\"results\"],[12],[1,\"\\n          \"],[10,0],[14,0,\"close-mobile\"],[12],[1,\"\\n            \"],[10,1],[14,0,\"close-mobile-icon\"],[12],[1,\"\\n              \"],[1,[28,[30,0,[\"icon\"]],[\"fa-xmark\"],[[\"label\"],[\"pdftron-search-close-button\"]]]],[1,\"\\n            \"],[13],[1,\"\\n          \"],[13],[1,\"\\n          \"],[10,0],[14,0,\"search-box\"],[12],[1,\"\\n            \"],[10,0],[14,1,\"algolia-search-box\"],[12],[13],[1,\"\\n            \"],[10,0],[14,1,\"stats\"],[12],[13],[1,\"\\n          \"],[13],[1,\"\\n          \"],[10,0],[14,0,\"hits\"],[12],[13],[1,\"\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],null]],[],false,[\"if\"]]",
    "moduleName": "discourse/plugins/discourse-pdftron-search/discourse/templates/connectors/home-logo__after/pdftron-search.hbs",
    "isStrictMode": false
  });
});